<template>
  <div class="process-on">
    <admin-title title="数据同步"></admin-title>
    <el-form style="margin-top:30px" ref="form" :model="formData">
      <el-form-item label="平台" prop="sysCode" :rules="[{ required: true, message: '平台必选', trigger: 'blur' }]">
        <el-select v-model="formData.sysCode" clearable placeholder="请选择">
          <el-option v-for="item in sysCodeList" :key="item.value" :label="item.name" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item>
        <el-progress :text-inside="true" :stroke-width="24" :percentage="100" status="success"></el-progress>
      </el-form-item> -->
      <!-- <el-form-item>
        <el-button type="primary" @click="doSync">数据同步</el-button>
      </el-form-item> -->
    </el-form>
    <el-divider></el-divider>
<!--    <div>-->
<!--      <el-progress :text-inside="true" :stroke-width="24" :percentage="percentage" status="success"></el-progress>-->
<!--    </div>-->
    <el-button style="margin:30px 0" type="primary" @click="doSync">数据同步</el-button>
  </div>
</template>

<script>
import { syncAPI } from './api'
import { getDictEnumListAPI } from '@/enum/dict'

export default {
  name: 'List',

  data() {
    return {
      percentage: 0,
      timer: '',
      formData: {
        sysCode: ''
      },
      sysCodeList: []
    }
  },

  mounted() {
    this.getDictEnumList()
  },

  methods: {
    doSync() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          const res = await syncAPI(this.formData.sysCode)
          this.$alert(res?.data?.alert || '数据同步请求正在处理，请稍后查看', '友情提示', {
            confirmButtonText: '确定',
            type: 'success',
          });
        } else {
          return false
        }
      })
    },
    async getDictEnumList() {
      this.sysCodeList = await getDictEnumListAPI('identity:sys')
      console.log(this.sysCodeList)
    }
  }
}
</script>

<style lang="scss" scoped>
.process-on {
  width: 100%;
  // margin: 0 auto;
  // padding-top: 30px;

  // .el-form-item {
  //   margin-top: 50px;
  // }
}
</style>
